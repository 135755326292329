import React, {useCallback, useEffect, useState} from "react";
import ElementControl from "./components/ElementControl";
import {ApiAdminPanel} from "../../api/ApiAdminPanel";
import { User} from "../../api/types";
import Loader from "../../../components/Loader";
import FormikTextField from "../../../components/formik/FormikTextField";
import {Form, Formik} from "formik";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(()=>({
  botSettingContainer: {
    marginTop: 20,
    flexWrap: "wrap",
    gap: 10,
    width: 500,
    maxWidth: 500,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  boxTextForSending: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  text: {
    flexGrow: 6,
  },
}))

const Admin = () => {
  const [users,setUsers] = useState<User[]>([]);
  const [loading,setLoading] = useState<boolean>();
  const classes = useStyles();

  useEffect(()=>{
    Promise.all([
      ApiAdminPanel.getUsersAdmin().then((users)=>{
        setUsers(users);
      }),
    ]).then(()=>setLoading(false)).catch(()=>setLoading(false))
  }, [])

  const removeUser = useCallback(
    async (idUser:string) => {
      if(await ApiAdminPanel.removeUser(idUser)){
        setUsers((bots) =>
          bots.filter(({id})=>id!==idUser)
        )
      }
    },
    []
  )

  if(loading)
    return <Loader />

  return (
    <div className="row" style={{minWidth:800}}>
      <Formik
        initialValues={{
          email: "",
          pass: "",
        }}
        onSubmit={(values, { resetForm }) => {
          const { email, pass } = values
          setLoading(true)
          ApiAdminPanel.addUser(email, pass)
            .then((user: User|undefined) => {
              if(user){
                setUsers((users)=>[...users, user])
                resetForm();
              }
              setLoading(false)
            })
            .catch(()=>setLoading(false))
        }}
      >
        {({ isSubmitting }) => (
            <Form className={classes.botSettingContainer}>
              <div className={classes.boxTextForSending}>
                <FormikTextField className={classes.text} disabled={isSubmitting} name={"email"} label={"Email"}/>
                <FormikTextField className={classes.text} disabled={isSubmitting} name={"pass"} label={"Pass"}/>
              </div>
              <Button disabled={isSubmitting} type={"submit"} name={"save"} variant={"contained"}>Add</Button>
            </Form>
        )}
      </Formik>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-dark">
                <thead className="thead">
                <tr>
                  <th>email</th>
                  <th>role</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                  users&&users.map(user=>
                    <ElementControl
                      key={user.id}
                      id={user.id}
                      email={user.email}
                      role={user.role}
                      removeUser={removeUser}
                    />
                  )
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin
