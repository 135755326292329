import {Audio} from "react-loader-spinner";
import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(()=>({
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  }
}))

const Loader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Audio
        height="100"
        width="100"
        color="#1976d2"
        ariaLabel="audio-loading"
        wrapperStyle={{}}
        wrapperClass="wrapper-class"
        visible={true}
      />
    </div>
  )
}

export default Loader
