import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import axios from '../app/api/axios';
import {User} from "../app/api/types";
import Loader from "../components/Loader";

enum TypeAuthState {
  INITIALISE,
  LOGIN,
}

interface AuthState {
  isAuthenticated?: boolean;
  isInitialised?: boolean;
  role?: string | null;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  role: null
};

interface AuthStateAction {
  payload: AuthState,
  type: TypeAuthState,
}

const reducer = (state: AuthState, action: AuthStateAction) => {
  switch (action.type) {
    case TypeAuthState.INITIALISE: {
      const { isAuthenticated,role } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        role
      };
    }
    case TypeAuthState.LOGIN: {
      const { role } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        role
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: (login:string, password: string) => Promise.resolve(),
});

export const AuthProvider: React.FC<{children:any}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (login:string, password: string) => {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const params = new URLSearchParams()
      params.append('email', login)
      params.append('password', password)

      const response = (await axios.post<{user: User}>('login', params, config));

      dispatch({
        type: TypeAuthState.LOGIN,
        payload: {
          role:response.data.user.role
        }
      });
  }

  useEffect(() => {
    const initialise = async () => {
      try {

        const response = await axios.get('/user/get');

        dispatch({
          type: TypeAuthState.INITIALISE,
          payload: {
            isAuthenticated: true,
            role:response.data.user.role
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: TypeAuthState.INITIALISE,
          payload: {
            isAuthenticated: false
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <Loader />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
      }}
    >
      {children}
  </AuthContext.Provider>
);
};

export default AuthContext;
