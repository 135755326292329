import Login from "../Login";
import React from "react";
import useAuth from "../../hooks/useAuth";
import LeftMenu from "../../../components/LeftMenu";

const Constructor = () => {
  const{isAuthenticated} = useAuth()

  if (!isAuthenticated) {
    return <Login />
  }

  return <LeftMenu />
}

export default Constructor;
