import React, {useCallback, useEffect, useState} from "react";
import ElementControl from "./components/ElementControl";
import {ApiAdminPanel} from "../../api/ApiAdminPanel";
import {Bot, Settings} from "../../api/types";
import Loader from "../../../components/Loader";
import FormikTextField from "../../../components/formik/FormikTextField";
import {Form, Formik} from "formik";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(()=>({
  botSettingContainer: {
    marginTop: 20,
    flexWrap: "wrap",
    gap: 10,
    width: 500,
    maxWidth: 500,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  boxTextForSending: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  text: {
    flexGrow: 6,
  },
}))

const BotSendersLists = () => {
  const [bots,setBots] = useState<Bot[]>([]);
  const [loading,setLoading] = useState<boolean>();
  const classes = useStyles();

  useEffect(()=>{
    Promise.all([
      ApiAdminPanel.getBotsSender().then((bots)=>{
        setBots(bots);
      }),
    ]).then(()=>setLoading(false)).catch(()=>setLoading(false))
  }, [])

  const changeStatus = useCallback(
    async (idBot:string, status:boolean)=> {
      const statusAfterChanges = /^true$/i.test(String(await ApiAdminPanel.setStatusBotSender(idBot, status)))
      setBots((bots) =>
        bots.map((bot) => {
            if (bot.id !== idBot)
              return bot

            return {
              ...bot,
              started: statusAfterChanges
            }
          }
        )
      )
    },
    []
  );

  const removeBot = useCallback(
    async (idBot:string) => {
      if(await ApiAdminPanel.removeBotSender(idBot)){
        setBots((bots) =>
          bots.filter(({id})=>id!==idBot)
        )
      }
    },
    []
  )

  if(loading)
    return <Loader />

  return (
    <div className="row" style={{minWidth:800}}>
      <Formik
        initialValues={{
          token: "",
          textSending: "",
          timeToDispatch: 1,
        }}
        onSubmit={(values, { resetForm }) => {
          const { token, textSending, timeToDispatch } = values
          setLoading(true)
          ApiAdminPanel.addBotSender(token, textSending, timeToDispatch)
            .then((bot: Bot|undefined) => {
              if(bot){
                setBots((bots)=>[...bots, bot])
                resetForm();
              }
              setLoading(false)
            })
            .catch(()=>setLoading(false))
        }}
      >
        {({ isSubmitting }) => (
            <Form className={classes.botSettingContainer}>
              <div className={classes.boxTextForSending}>
                <FormikTextField className={classes.text} disabled={isSubmitting} name={"textSending"} label={"Text for sending"}/>
                <FormikTextField type={"number"} disabled={isSubmitting} name={"timeToDispatch"} label={"Time to dispatch(sec)"}/>
              </div>
              <FormikTextField disabled={isSubmitting} name={"token"} label={"Token"}/>
              <Button disabled={isSubmitting} type={"submit"} name={"save"} variant={"contained"}>Add</Button>
            </Form>
        )}
      </Formik>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-dark">
                <thead className="thead">
                <tr>
                  <th>Token</th>
                  <th></th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                  bots&&bots.map(bot=>
                    <ElementControl
                      key={bot.id}
                      id={bot.id}
                      token={bot.token}
                      status={bot.started}
                      removeBot={removeBot}
                      changeStatus={changeStatus}
                    />
                  )
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BotSendersLists
