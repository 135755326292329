import axios from "./axios";
import {Bot, GetBot, GetBots, GetBotStatus, GetSettings, GetUser, GetUsers, Settings, User} from "./types";

export class ApiAdminPanel {
  static async getSettings(): Promise<Settings | undefined> {
    const response = await axios.get<GetSettings>('api/settings');
    return response.data.data
  }

  static async setSettings(
      stopWords:string,
      whiteWords:string,
      botToken:string,
      removeSystemMessagesV1:boolean,
      removeImages:boolean,
      ) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('stopWords', stopWords)
    params.append('whiteWords', whiteWords)
    params.append('botToken', botToken)
    params.append('removeSystemMessagesV1', removeSystemMessagesV1.toString())
    params.append('removeImages', removeImages.toString())

    return await axios.post<GetSettings>('api/settings', params,config);
  }

  //#region bot monitoring
  static async getBots(): Promise<Bot[]> {
    const response = await axios.get<GetBots>('api/bot/getList');
    return response.data.data || []
  }

  static async removeBot(idBot: string) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('idBot', idBot)

    await axios.post<GetBot>('api/bot/remove',params,config);
    return true
  }

  static async addBot(token: string): Promise<Bot | undefined> {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('token', token)

    const response = await axios.post<GetBot>('api/bot/add',params,config);
    return response.data.data
  }

  static async setStatusBot(idBot:string, started: boolean): Promise<boolean> {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('started', started.toString())
    params.append('idBot', idBot)

    const response = await axios.post<GetBotStatus>('api/bot/setStatus',params,config);
    return response.data.data?.started || false
  }
//#endregion


  //region users
  static async getUsersAdmin(): Promise<User[]> {
    const response = await axios.get<GetUsers>('admin/users/get');
    return response.data.data || []
  }

  static async removeUser(id: string) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('id', id)

    await axios.post<GetUsers>('admin/user/remove',params,config);
    return true
  }

  static async addUser(email: string, password: string): Promise<User | undefined> {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)

    const response = await axios.post<GetUser>('admin/user/add',params,config);
    return response.data.data
  }
  //endregion

  //#region bot sender
  static async getBotsSender(): Promise<Bot[]> {
    const response = await axios.get<GetBots>('api/botSender/getList');
    return response.data.data || []
  }

  static async removeBotSender(idBot: string) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('idBot', idBot)

    await axios.post<GetBot>('api/botSender/remove',params,config);
    return true
  }

  static async addBotSender(token: string, textSending: string, timeToDispatch: number): Promise<Bot | undefined> {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('token', token)
    params.append('textSending', textSending)
    params.append('timeToDispatch', timeToDispatch.toString())

    const response = await axios.post<GetBot>('api/botSender/add',params,config);
    return response.data.data
  }

  static async setStatusBotSender(idBot:string, started: boolean): Promise<boolean> {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const params = new URLSearchParams()
    params.append('started', started.toString())
    params.append('idBot', idBot)

    const response = await axios.post<GetBotStatus>('api/botSender/setStatus',params,config);
    return response.data.data?.started || false
  }
  //#endregion
}
