import { Field } from "formik";
import React, { FunctionComponent } from "react";
import {TextField, TextFieldProps} from "@mui/material";

const component = (props: any) => {
  const { field, form, ...rest } = props;

  const errors = form.errors[field.name];

  const error = Array.isArray(errors) ? errors[0] : errors;

  return <TextField error={form.touched && error} helperText={form.touched && error} {...field} {...rest} />;
};

const FormikTextField: FunctionComponent<TextFieldProps> = (props: TextFieldProps) => (
  <Field {...props} component={component} />
);

export default FormikTextField;
