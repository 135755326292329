import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Button} from "@mui/material";
import {Form, Formik} from "formik";
import {Settings} from "../../api/types";
import {ApiAdminPanel} from "../../api/ApiAdminPanel";
import FormikTextField from "../../../components/formik/FormikTextField";
import Loader from "../../../components/Loader";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles(()=>({
  botSettingContainer: {
    marginTop: 20,
    flexWrap: "wrap",
    gap: 10,
    width: 500,
    maxWidth: 500,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 10,
  }
}))

const Login = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false)
  const {login: loginMethod} = useAuth()

  if (loading)
    return <Loader/>

  return (
    <div className={classes.root}>
      <div className={classes.botSettingContainer}>
        <Formik
          initialValues={{
            login: "",
            password: "",
          }}
          onSubmit={(values:{login:string, password: string}) => {
            const { login, password } = values
            setLoading(true)
            loginMethod(login, password)
              .then(() => {
                setLoading(false)
              })
              .catch(()=>setLoading(false))
          }}
        >
          <Form className={classes.botSettingContainer}>
            <FormikTextField name={"login"} label={"Login"}/>
            <FormikTextField name={"password"} label={"Password"}/>
            <Button type={"submit"} name={"login"} variant={"contained"}>Login</Button>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Login
