import React from 'react';
import {AuthProvider} from "./context/JWTAuthContext";
import Constructor from "./app/pages/Constructor";

const App: React.FC = () => {

  return (
    <AuthProvider>
      <Constructor/>
    </AuthProvider>
  )
};

export default App;
