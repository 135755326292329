import React, {useState,useMemo} from "react"
import {makeStyles} from "@mui/styles";
import {Button} from "@mui/material";
import BotSettings from "../../app/pages/BotSettings";
import BotLists from "../../app/pages/BotLists";
import BotSendersLists from "../../app/pages/BotSendersLists";
import Admin from "../../app/pages/Admin";
import useAuth from "../../app/hooks/useAuth";

const useStyles = makeStyles(()=>({
  root: {
    display: "flex",
    flex: 1,
    borderBottom: "2px dotted #1976d2",
  },
  leftMenu: {
    display: "flex",
    minWidth: 200,
    height: 700,
    flexDirection: "column",
    borderRight: "2px dotted #1976d2",
    alignItems: "center",
    justifyContent: "left",
    marginRight: 30,
  },
  button: {
    marginBottom: 5,
  }
}))


function LeftMenu () {
  const classes = useStyles();
  const{role} = useAuth()
  const [tab, setTab] = useState<number>(2)

  const activeTab = useMemo(()=>{
    switch(tab){
      case 1:
        return <BotSettings />
      case 2:
        return <BotLists />
      case 3:
        return <BotSendersLists />
      case 4:
        return <Admin />
      default:
        return <BotSettings />
      }
    }, [tab])

  return (
    <div className={classes.root}>
      <div className={classes.leftMenu}>
        <div className={classes.button}>
          <Button onClick={()=>setTab(1)} variant={"contained"}>Settings</Button>
        </div>
        <div className={classes.button}>
          <Button onClick={()=>setTab(2)} variant={"contained"}>Bots</Button>
        </div>
        <div className={classes.button}>
          <Button onClick={()=>setTab(3)} variant={"contained"}>Bots Senders</Button>
        </div>
        {role==="admin"&&<Button onClick={() => setTab(4)} variant={"contained"}>Admin panel</Button>}
      </div>
      <div className={classes.root}>
        {activeTab}
      </div>
    </div>
  )
}

export default LeftMenu;
