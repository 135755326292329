import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Button, Checkbox, FormControlLabel} from "@mui/material";
import {Formik, Form, Field} from 'formik';
import FormikTextField from "../../../components/formik/FormikTextField";
import {Settings} from "../../api/types";
import {ApiAdminPanel} from "../../api/ApiAdminPanel";
import Loader from "../../../components/Loader";

const useStyles = makeStyles(()=>({
  botSettingContainer: {
    marginTop: 20,
    flexWrap: "wrap",
    gap: 10,
    width: 500,
    maxWidth: 500,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  root: {
    display: "flex",
    flex: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 10,
  }
}))

const BotSettings = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true)
  const [defaultSettings, setDefaultSettings] = useState<Settings>()

  useEffect(()=>{
    Promise.all([
      ApiAdminPanel.getSettings().then((settings)=>{
        setDefaultSettings(settings);
      }),
    ]).then(()=>setLoading(false)).catch(()=>setLoading(false))
  }, [])

  if (loading)
    return <Loader/>

  return (
    <div className={classes.root}>
      <div className={classes.botSettingContainer}>
        <Formik
          initialValues={{
            stopWords: defaultSettings?.stopWords || "",
            whiteWords: defaultSettings?.whiteWords || "",
            token: defaultSettings?.token || "",
            removeSystemMessagesV1: defaultSettings?.removeSystemMessagesV1 || false,
            removeImages: defaultSettings?.removeImages || false,
          }}
          onSubmit={(values:Settings) => {
            const { stopWords, whiteWords, token, removeSystemMessagesV1, removeImages } = values
            setLoading(true)
            ApiAdminPanel.setSettings(stopWords, whiteWords, token, removeSystemMessagesV1, removeImages)
              .then(r => {
                setDefaultSettings({stopWords, whiteWords, token, removeSystemMessagesV1, removeImages})
                setLoading(false)
              })
              .catch(()=>setLoading(false))
          }}
        >
          <Form className={classes.botSettingContainer}>
            <FormikTextField rows={20} name={"stopWords"} label={"Stop words"} multiline />
            <Field
              type="checkbox"
              name="removeSystemMessagesV1"
              as={FormControlLabel}
              control={<Checkbox />}
              label="Remove system message"
            />
            <Field
              type="checkbox"
              name="removeImages"
              as={FormControlLabel}
              control={<Checkbox />}
              label="Remove all images"
            />
            <Button type={"submit"} name={"save"} variant={"contained"}>Save</Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default BotSettings
