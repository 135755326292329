import {IElementControl} from "./types";
import React from "react";
import {makeStyles} from "@mui/styles";
import {Button} from "@mui/material";

const useStyles = makeStyles(()=>({
  td: {
    borderBottom: '1px solid',
    paddingBottom: 5,
    fontSize: '1rem',
  }
}))

const ElementControl: React.FC<IElementControl> = ({id, role, removeUser, email}) => {
  const classes = useStyles()

  return(
    <tr key={id}>
      <td className={classes.td}>
        {email}
      </td>
      <td className={classes.td}>
        {role}
      </td>
      <td className={classes.td}>
        <Button
          onClick={()=>removeUser(id)}
          color={"warning"}
          variant={"outlined"}>
          {"Remove"}
        </Button>
      </td>
    </tr>
  )
}

export default ElementControl
